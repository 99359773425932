import React from "react";

import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  ListItem,
  List,
  Button,
} from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    background:
      "linear-gradient(90deg, rgba(63,85,185,1) 0%, rgba(35,123,189,1) 100%)",
    position: "relative",
    padding: "70px 0px 0",
    overflow: " hidden",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    "& h3": {
      color: "#fff",
      fontWeight: 800,
      fontSize: "18px",
      marginBottom: "1rem",
      marginLeft: "13px",
    },
    "& a": {
      fontWeight: 500,
      textDecoration: "none",
      fontSize: "14px",
      display: "flex",
      color: "#fff",
      alignItems: "center",
      cursor: "pointer",
      textTransform: "none",
      transition: "padding-left 0.6s ease",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        textAlign:"center"
      },
      "&:hover": {
        paddingLeft: "2.5rem",
      },
    },
    "& .MuiListItem-root":{
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        textAlign:"center"
      },
    },
    "& .borderBox": {
      position: "absolute",
      left: "153px",
      top: "12px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  bottomText: {
    paddingY: "1rem",
    fontWeight: "400",
    color: "#fff",
    fontSize: "16px",
  },
  footerImg: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  spanTag: {
    height: "3px",
    width: "30px",
    marginTop: "30px",
    marginLeft: "5px",
    background: "linear-gradient(left, #0660E7, #00F6FF)",
    [theme.breakpoints.down("sm")]: {
      height: "0px",
      width: "0px",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  <Box className={classes.footerImg}>
                    <Typography variant="h3">Learn</Typography>
                    <Box className={classes.spanTag} />
                  </Box>
                  <List>
                    <ListItem to="/#" component={RouterLink}>
                      Basics
                    </ListItem>
                    <ListItem to="/#" component={RouterLink}>
                      Roadmap
                    </ListItem>
                    <ListItem to="/#" component={RouterLink}>
                      Use Cases
                    </ListItem>
                    <ListItem to="/#" component={RouterLink}>
                      Learning
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  <Box className={classes.footerImg}>
                    <Typography variant="h3">Docx</Typography>
                    <Box className={classes.spanTag} />
                  </Box>
                  <List>
                    <ListItem to="/#" component={RouterLink}>
                      Platform
                    </ListItem>
                    <ListItem to="/#" component={RouterLink}>
                      Consensus
                    </ListItem>
                    <ListItem to="/#" component={RouterLink}>
                      Native Coin
                    </ListItem>
                    <ListItem to="/#" component={RouterLink}>
                      Tarality Chain Finance
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  <Box className={classes.footerImg}>
                    <Typography variant="h3">Ecosystem</Typography>
                    <Box className={classes.spanTag} />
                  </Box>
                  <List>
                  <ListItem >
                    <a href="https://pepperwallet.io" target="_blank" rel="noopener noreferrer" >
                      Pepper Wallet
                    </a>
                    </ListItem>
                    <ListItem >
                    <a href="https://calculas.finance/" target="_blank" rel="noopener noreferrer" >
                      Calculus Swap
                    </a>
                    </ListItem>
                    <ListItem to="/#" component={RouterLink}>
                    Tarality Chain Explorer
                    </ListItem>
                    <ListItem >
                    <a href="https://xtring.network/" target="_blank" rel="noopener noreferrer" >
                      Xtring Bridge
                    </a>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  <Box className={classes.footerImg}>
                    <Typography variant="h3">Community</Typography>
                    <Box className={classes.spanTag} />
                  </Box>
                  <List>
                    <ListItem to="/#" component={RouterLink}>
                      Deo Foundation
                    </ListItem>
                    <ListItem to="/community" component={RouterLink}>
                      Community
                    </ListItem>
                    <ListItem to="/#" component={RouterLink}>
                      Events
                    </ListItem>
                    <ListItem to="/#" component={RouterLink}>
                      INRx Foundation
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  <Box className={classes.footerImg}>
                    <Typography variant="h3">Developers</Typography>
                    <Box className={classes.spanTag} />
                  </Box>
                  <List>
                    <ListItem to="/#" component={RouterLink}>
                      Rabbit Testnet
                    </ListItem>
                    <ListItem to="/#" component={RouterLink}>
                      Testnet Faucet
                    </ListItem>
                    <ListItem to="/#" component={RouterLink}>
                      Xide Work
                    </ListItem>
                    <ListItem to="/#" component={RouterLink}>
                      Developer API
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  <Box className={classes.footerImg}>
                    <Typography variant="h3">About</Typography>
                    <Box className={classes.spanTag} />
                  </Box>
                  <List>
                    <ListItem to="/#" component={RouterLink}>
                      Blog
                    </ListItem>
                    <ListItem to="/about" component={RouterLink}>
                      About
                    </ListItem>
                    <ListItem to="/brand-assets" component={RouterLink}>
                      Brand Asset
                    </ListItem>
                    <ListItem to="/jobs" component={RouterLink}>
                      Careers
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Box mt={4} mb={4}>
          <Container>
            <Box sx={{ float: "right" }} mb={4}>
              <Button
                variant="contained"
                style={{
                  borderRadius: "50%",
                  background: "#81d742",
                  width: "3.5rem",
                  padding:"0px",
                  fontSize:"16px",
                  minWidth: "0",
                }}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                <FaArrowUp style={{ color: "#fff", }} />
              </Button>
            </Box>
            <Box mt={3} pt={2}>
              <Typography
                variant="body2"
                align="center"
                className={classes.bottomText}
              >
                © 2023 Tarality Chain Labs LLC |
                <Button
                  onClick={() => history.push("/disclaimer")}
                  variant="text"
                  className={classes.bottomText}
                >
                  Disclaimer
                </Button>{" "}
                |{" "}
                <Button
                  onClick={() => history.push("/privacy-policy")}
                  className={classes.bottomText}
                  variant="text"
                >
                  Privacy
                </Button>
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
