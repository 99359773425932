import _ from 'lodash';
import {  createTheme, responsiveFontSizes } from '@material-ui/core';
import typography from './typography';

const baseOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiPickerDTTabs: {
      tabs: {
        color: 'none',
        backgroundColor: 'none',
      },
    },
    MuiDropzoneArea: {
      test: {
        opacity: '1',
      },
      root: {
        minHeight: '284px',
        '@media (max-width:767px)': {
          minHeight: '184px',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        background: 'rgb(20 20 20)',
        borderRadius: '10px',
      },
    },
    MuiRating: {
      root: {
        fontSize: '14px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '10px !important',
        paddingTop: '10px !important',
      },
    },
    MuiFormGroup: {
      root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
      },
    },
    MuiInputBase: {
      root: {
        color: '#fff',
      },
      input: {
        color: '#FFFFFF',
        height: '1.1876em ',
        '&::placeholder': {
          color: '#FFFFFF',
        },
      },
    },

    Component: {
      paper: {
        backgroundColor: '#2F3336 !important',
      },
    },
    MuiListSubheader: {
      root: {
        color: '#404040',
        fontSize: '18px',
        boxSizing: 'border-box',
        listStyle: 'none',
        fontWeight: '500',
        lineHeight: '48px',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#EDEDED',
      },
    },

    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: '30px',
      },
    },

    MuiDialog: {
      paperFullScreen: {},
      paper: {
        background: '#171717 !important',
        overflow: 'hidden',
        position: 'relative',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        padding: '20px',

        '&::before': {
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          content: "''",
          zIndex: '-1',
          position: 'absolute',
          backdropFilter: 'blur(30px)',
        },
      },
    },
    MuiSelect: {
      icon: {
        color: '#535353',
      },
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        paddingLeft: '15px',
        borderRadius: '18px',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#ACACAC',
      },
    },
    MuiPickersToolbar: {
    
      toolbar: {
        backgroundColor: '#fdc763',
      },
    },
    
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: '#FFFFFF !important',
      },
    },
    MuiTable: {
      root: {
        borderCollapse: 'separate',
        borderSpacing: '0px 2px',
      },
    },
    MuiTableCell: {
      body: {
        padding: '10px 15px',
        color: '#fff',
      },
      root: {
        borderBottom: 'none',
        background: 'transparent',
        fontSize: '12px',
      },
      head: {
        background: 'transparent',
        color: 'rgba(255, 255, 255, 0.6)',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        whiteSpace: 'pre',
      },
    },
    MuiTableRow: {
      root: {
        '&:hover': {
          background: '#FFFFFF05',
        },
      },
    },
    MuiPaginationItem: {
      page: {
        '& .Mui-selected': {
          background: 'rgba(255, 255, 255, 0.05) !important',
        },
      },
      ellipsis: {
        height: '32px',
      },
      root: {
        backgroundColor: '#191919',
        opacity: '1',
        color: '#fff',
      },
    },
    MuiInput: {
      root: {
        borderRadius: '10px',
      },
      underline: {
        '&::before': {
          left: '0',
          right: '0',
          bottom: '0',
          content: '"\\00a0"',
          position: 'absolute',
          transition:
            'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
          pointerEvents: 'none',
        },
      },
    },

    MuiFormControlLabel: {
      root: {
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        marginLeft: '1px',
        verticalAlign: 'middle',
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: '0px',
      },
    },
    MuiIconButton: {
      root: {
        flex: '0 0 auto',
        color: '#ACACAC',
        padding: '8px',
        overflow: 'visible',
        fontSize: '1.5rem',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: 'none',
        },
      },
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: '14px',
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: 'none',
        },
      },
      fullWidth: {
        width: '100%',
      },
      contained: {
        background: '-webkit-linear-gradient(left, #009BFD, #0ADF73)',
        padding: '0.7rem 1.5rem',
        height: '3.5rem',
        minWidth: '10rem',
        fontFamily: 'Barlow',
        fontSize: '15px',
        borderRadius: '3px',
        color: '#fff',
        boxShadow: '0 7px 16px rgba(12, 12, 12, 0.22)',
        fontWeight: '500',
        position: 'relative',
        overflow: 'hidden',
        '@media (max-width:767px)': {
          fontSize: '12px',
        },
        '&::after': {
          content: '""',
          background: '#fff',
          WebkitTransition: 'all 0.8s',
          MsTransition: 'all 0.8s',
          transition: 'all 0.8s',
          position: 'absolute',
          height: '120%',
          width: '15px',
          opacity: '0.2',
          left: '-35%',
          top: '-10%',
          transform: 'rotate(15deg)',
          MozTransform: 'rotate(15deg)',
          WebkitTransform: 'rotate(15deg)',
          OTransform: 'rotate(15deg)',
        },
        '&:hover': {
          transition: 'all 550ms cubic-bezier(0.19, 1, 0.22, 1)',
          '&::after': {
            left: '130%',
          },
        },
      },
      outlined: {
        boxShadow: 'none',
        border: '1px solid #FDC763',
        color: '#fff',
      },
      textPrimary: {
        color: '#404040',
        fontWeight: 400,
        fontSize: '14px !important',
        lineHeight: '17px',
      },
      containedSizeSmall: {
        padding: '4px 10px',
        fontSize: '0.8125rem',
      },
      containedPrimary: {
        border: '1px solid rgba(255, 255, 255, 0.25)',
        borderRadius: '50px',
        color: '#fff',
        backgroundColor: 'transparent',
        '&:hover': {
          color: '#fff !important',
          border: '1px solid rgba(255, 255, 255, 0.25)',
          background:
            'linear-gradient(108.43deg, #C7762B 0%, #FDC763 100%) !important',
        },
      },
      containedSecondary: {
        background: 'linear-gradient(108.43deg, #C7762B 0%, #FDC763 100%)',
        color: '#fff',

        border: '1px solid #FDC763',
        '&:hover': {
          color: '#fff',
          border: '1px solid rgba(255, 255, 255, 0.25)',
          background: 'rgba(255, 255, 255, 0.05) !important',
        },
      },

      outlinedSecondary: {
        color: '#6FCFB9',
        border: '1px solid #6FCFB9',
        backgroundColor: '#fff',
        '&:hover': {
          backgroundColor: '#6FCFB9',
          color: '#fff',
          border: '1px solid #6FCFB9',
        },
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiPaper: {
      root: {
        border: '1px solid rgb(14 14 14 / 98%)',
        color: '#fff !important',
        borderRadius: '0px !important',
      },

        elevation1: {
        boxShadow: 'none',
        padding: '15px',
      },
    },

    MuiList: {
      padding: {
        paddingTop: 'none',
        paddingBottom: 'none',
      },
    },

    MuiOutlinedInput: {
      root: {
        borderRadius: '0px',
        backgroundColor: '#fff',
      },
      input: {
        borderRadius: '0px',
        backgroundColor: '#fff',
        fontSize: '16px',
        color: '#000',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },

    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#ffffff',
      },
    },
  },
};

const themesOptions = [
  {
    name: 'LIGHT',
    overrides: {
      MuiInputBase: {
        root: {},
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#FFFFFF',
          },
        },
      },
    },
    palette: {
      type: 'light',
      action: {
        active: '#FDC763',
      },
      background: {
        default: 'rgba(255, 255, 255, 1);',
        dark: '#f4f6f8',
        paper: '#ECECEC',
      },
      secondary: {
        main: '#FF9933',
      },
      text: {
        primary: '#FDC763',
        secondary: '#FDC763',
      },
    },
  },
  {
    name: 'DARK',
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#F5F5F5;',
        dark: '#1c2025',
        paper: '#ffffff',
      },
      primary: {
        main: '#FDC763',
      },
      secondary: {
        main: '#FDC763',
      },
      text: {
        primary: '#404040',
        secondary: '#FDC763',
      },
    },
  },
];

export const createTheme1 = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config?.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
