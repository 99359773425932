import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Hidden,
  Grid,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },

  iconbutton: {

    position: "relative",

    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },
    "& div": {
      height: "5px",
      width: "5px",
      borderRadius: "50%",
      backgroundColor: "#6FCFB9",
      position: "absolute",
      top: "7px",
      right: "8px",
    },
  },
  mainButton: {
    color: "#fff",
    background: "#06B467 !important",
    borderRadius: "50px",
    fontWeight: "500",
    fontSize: "16px",
    marginLeft: "auto",
    textTransform: "none",
    padding: "15px 20px !imortant",
    height:"40px",
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  mainheader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    "& .leftBox": {
      width: "210px",
      display: "flex",
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "150px",
      },
      "& img": {
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0 !important",
        },
      },
    },
    "& button": {
      color: "#000000",
      fontSize: "17px",
      fontWeight: "600",
      marginTop: "8px",
      padding: "0px 15px",
      textTransform: "none",
      "&:active": {
        color: "#42ce64",
      },
      "&:focus": {
        color: "#42ce64",
      },
      "&:hover": {
        color: "#42ce64",
      },
      "&[tabIndex]:focus": {
        color: "#1EB808",
      },
    },
    "& .MuiOutlinedInput-input": {
      paddingRight: "12px",
    },
  },

  ProfileNotification: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const createAccountButton = {
  color: "#fff",
  background: "#06B467 !important",
  borderRadius: "50px",
  fontWeight: "500",
  fontSize: "16px",
  marginLeft: "auto",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#000",
  },
};

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData({ type }) {

  const { mainheader } = useStyles();

  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);
  const classes = useStyles();
  return (
    <>
      <Hidden mdDown>
        <Box className={mainheader}>
          <Grid container alignItems="center" justifyContent="space-between">
            {/* <Box className="leftBox">
              <Logo width="125" type={type} />
            </Box> */}

            <Button
              className="active"
              onClick={() => history.push("/learn")}
              variant="text"
            >
              Learn
            </Button>
            <Button
              className="active"
              onClick={() => {
                history.push("/use");
              }}
              variant="text"
              tabIndex="1"

            >
              Use
            </Button>
            <Button
              className="active"
              onClick={() => history.push("/ecosystem")}
              variant="text"
              tabIndex="2"

            >
              Ecosystem
            </Button>
            <Button
              className="active"
              onClick={() => history.push("/developers")}
              variant="text"
            >
              Developers
            </Button>
            <Button
              className="active"
              onClick={() => {
                history.push("/about");
              }}
              variant="text"
            >
              About
            </Button>
            <Button
              className={classes.mainButton}
              style={createAccountButton}
            >
              Create account
            </Button>
          </Grid>
        </Box>
      </Hidden>
    </>
  );
}
