import React from "react";
import { useHistory } from "react-router-dom";
const Logo = (props) => {
  const history = useHistory();
  return (
    <img
      className="Logoimg"
      onClick={() => history.push("/")}
      src="images/monologo.png"
      alt="Logo"
      style={{ cursor: "pointer", paddingTop: "5px", width: "150px" }}
      {...props}
    />
  );
};

export default Logo;
