import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    // guard: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/use",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Use/index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/learn",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Learn/index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/developers",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Developers/index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/ecosystem",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Ecosystem/index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/disclaimer",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticContent/Disclaimer")),
  },
  {
    exact: true,
    // guard: true,
    path: "/brand-assets",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/BrandAssets/index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/PrivacyPolicy")
    ),
  },
  {
    exact: true,
    // guard: true,
    path: "/jobs",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Jobs/index")),
  },
  {
    exact: true,
    // guard: true,

    path: "/community",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Community/index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/tarality-chain-design-principal",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/AnalogDesignPrincipal/AnalogDesignPrincipal")
    ),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
