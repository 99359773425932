export default {
  h1: {
    fontSize: "48px",
    fontWeight: "600",
    fontFamily: "Manrope",
    lineHeight: "72px",
    "@media (max-width:767px)": {
      fontSize: "35px",
      lineHeight: "44px",
    },
  },
  h2: {
    fontSize: "42px",
    fontWeight: "600",
    fontFamily: "Manrope",
    lineHeight: "58px",
    "@media (max-width:767px)": {
      fontSize: "28px",
      lineHeight: "36px",
    },
  },
  h3: {
    fontSize: "36px",
    fontWeight: "600",
    fontFamily: "Manrope",
    lineHeight: "58px",
    "@media (max-width:767px)": {
      fontSize: "28px",
      lineHeight: "38px",
    },
  },
  h4: {
    fontSize: "34px",
    fontWeight: "600",
    fontFamily: "Manrope",
    lineHeight: "72px",
    "@media (max-width:767px)": {
      fontSize: "28px",
      lineHeight: "44px",
    },
  },
  h5: {
    fontWeight: 400,
    fontSize: 22,
    fontFamily: "Roboto",
    "@media (max-width:767px)": {
      fontSize: "16px",
    },
  },
  h6: {
    fontWeight: 300,
    fontFamily: "Barlow",
    fontSize: "18px",
    lineHeight: "30px",
    "@media (max-width:767px)": {
      fontSize: "16px",
    },
  },
  body2: {
    fontWeight: 300,
    fontSize: 14,
    fontFamily: "'Nunito Sans', sans-serif",
    color: "rgba(255, 255, 255, 0.6)",
    "@media (max-width:767px)": {
      fontSize: "12px",
    },
  },
  body1: {
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "'Nunito Sans', sans-serif",
    "@media (max-width:767px)": {
      fontSize: "10px",
    },
  },

  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Nunito Sans', sans-serif",
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "'Nunito Sans', sans-serif",
  },
  subtitle3: {
    fontWeight: 500,
    fontSize: 11,
    fontFamily: "'Nunito Sans', sans-serif",
  },
};
