import React, { useState, useEffect } from "react";
import Logo from "src/component/Logo";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  TextField,
  InputAdornment,

} from "@material-ui/core";
import { AiOutlineMenuFold } from "react-icons/ai";
import { TopBarData } from "src/layouts/HomeLayout/TopBar";

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { BiSearch } from "react-icons/bi";

const useStyles = makeStyles((theme) => ({
  topbarMain: {
    border: "none",
    backgroundColor: "#ffffff !important",
    backdropFilter: "blur(15px)",
  },
  toolbar: {
  
    [theme.breakpoints.down("xs")]: {
      paddingTop: "6px",
      paddingBottom: "6px",
      height: "35px",
      minHeight: "35px !important",
    },
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    "& .MuiPaper-root": {
      border: "none",
      backgroundColor: "#fff !important",
    },
    "& .MuiList-root": {
      width: "240px",
      "& .MuiListItemText-primary": {
        color: "black",
        fontSize: "16px",
        paddingBottom: "10px",
        borderBottom: "1px solid #dadada",
      },
    },
  },
  TextFieldBox: {
    padding: "0px 16px",
    "& .MuiOutlinedInput-input": {
      padding: "14.5px 14px",
    },
    "& svg": {
      fontSize: "16px",
    },
  },
  input: {
    "&::placeholder": {
      color: "#a2a2a2",
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, type, ...rest }) => {
  const classes = useStyles();
  // const location = useLocation();
  const [stickyClass, setStickyClass] = useState("relative");
  const [activeNavButton, setActiveNavButton] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const history = useHistory();
  const navLinks = [
    { label: "Learn", value: "learn" },
    { label: "Use", value: "use" },
    { label: "Ecosystem", value: "ecosystem" },
    { label: "Developers", value: "developers" },
    { label: "About", value: "about" },
  ];

  const handleNavButtonClick = (navButton) => {
    setActiveNavButton(navButton);
    handleDrawerClose();
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 5 ? setStickyClass("fixed") : setStickyClass("relative");
    }
  };
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  return (
    <AppBar
      style={{ border: "none" }}
      elevation={0}
      position="fixed"
      color="inherit"
      {...rest}
    >
      <Container>
        <Toolbar className={classes.toolbar}>
          <Box alignItems="left" mr={7}>
            <Logo type={type} />
          </Box>
          <Box flexGrow={1} textAlign="right">
            <Hidden lgUp>
              <IconButton
                onClick={() => {
                  onMobileNavOpen();
                  handleDrawerOpen();
                }}
              >
                <AiOutlineMenuFold style={{ color: "#000000" }} />
              </IconButton>
            </Hidden>
            <TopBarData type={type} />
          </Box>
        </Toolbar>

        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          className={classes.drawer}
        >
          <List>
            <Box mb={4} p={2}>
              <Logo type={type} />
            </Box>
            <Box className={classes.TextFieldBox}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Search..."
                name="search"
                InputProps={{
                  classes: { input: classes.input },
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <BiSearch style={{ color: "#000" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {navLinks.map((link) => (
              <ListItem
                button
                key={link.value}
                selected={activeNavButton === link.value}
                onClick={() => {
                  history.push(`/${link.value}`);
                  handleNavButtonClick(link.value);
                }}
              >
                <ListItemText primary={link.label} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
