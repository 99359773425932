import React, {  useState } from 'react';
import {  makeStyles } from '@material-ui/core';

import TopBar from 'src/layouts/DashboardLayout/TopBar';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: '#ffffff !important',
  },

  contentContainer: {
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    position: 'relative',
    WebkitOverflowScrolling: 'touch',

    [theme.breakpoints.down('sm')]: {
      zIndex: '9',
      overflow: 'hidden',
      padding: '0px 0 0px',
    },
  },
  mainBox_layout: {
    display: 'flex',
    width: '100%',
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} type='crypto' />
      <div className={classes.contentContainer}>
        <div className={classes.MainLayout}>{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
