import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: '#f0f4f7',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    padding: theme.spacing(3),
    position: 'relative',
    width: 'auto',
    maxWidth: '100%',
    zIndex: 2,
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        src='images/monologo.png'
        alt='Loading Page'
        style={{ width: 'auto', maxWidth: '250px' }}
      />
    </div>
  );
}
